<template>
	<b-modal
		id="invoice-overdue-modal" :visible="show" title="Plano e cobrança" ok-title="Concluir" centered no-close-on-backdrop hide-footer
	>
		<div style="padding: 10px;">
			<p>Olá <b>{{ name }}</b>, como vai?</p>
			<p>Nós sabemos que imprevistos acontecem e é normal perder alguns prazos.</p>
			<p>
				Por isso, estamos informando que o pagamento da(s) sua(s) fatura(s) do Eyecare BI
				está(ão) pendente(s):
			</p>

			<b-table-simple>
				<thead>
				<tr>
					<th>Emissão</th>
					<th>Vencimento</th>
					<th>Valor</th>
					<th>Regularize pelo link</th>
				</tr>
				</thead>

				<tbody>
				<tr v-if="overdue">
					<td>{{ overdue.created_at | date }}</td>
					<td>{{ overdue.due_at | date }}</td>
					<td>{{ overdue.amount | price }}</td>
					<td>
						<a :href="overdue.url" target="_blank">
							<b>Acesse aqui</b> <img src="/icons/export2.svg" alt="" height="20" width="20" />
						</a>
					</td>
				</tr>
				</tbody>
			</b-table-simple>

			<p>
				Caso tenha alguma dificuldade para fazer o pagamento ou dúvida, entre em contato com o nosso suporte!
			</p>

			<p>Suporte: <a href="#">financeiro@eyecarehealth.com.br</a></p>

			<b-button variant="primary" @click="$bvModal.hide('invoice-overdue-modal')">
				Fechar
			</b-button>
		</div>
	</b-modal>
</template>

<script>
import moment from "moment/moment";
import {parseNumberToMoney} from "@/utils/moneyHelper";

export default {
	name: "InvoiceOverdueModal",
	props: {
		show: {
			type: Boolean,
			default: false
		},

		overdue: {
			type: Object
		},

		name: String
	},

	filters: {
		date: (date) => moment(date).format('DD/MM/YYYY'),
		period: (obj) => `${moment(obj.start_at).format('DD/MM/YYYY')} - ${moment(obj.end_at).format('DD/MM/YYYY')}`,
		price: (value) => 'R$' + parseNumberToMoney(value).trim().split('$')[1]
	},

	methods: {
		close() {
			this.$bvModal.hide('invoice-overdue-modal')
			this.$emit('close', null)
		}
	}
}
</script>

<style>
#invoice-overdue-modal .modal-dialog{
	max-width: 600px !important;
}

#invoice-overdue-modal p{ margin-bottom: 15px; color: #525C7A; }
#invoice-overdue-modal .table thead th {
	background: #305BF2;
	color: #fff;
	border-bottom: none !important;
}

#invoice-overdue-modal .table thead{ border-top: 2px solid #305BF2; }
#invoice-overdue-modal .table tbody, #invoice-overdue-modal .table thead{
	border-left: 2px solid #305BF2;
	border-right: 2px solid #305BF2;
}

#invoice-overdue-modal .table{
	border-bottom-left-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

#invoice-overdue-modal .table tbody tr:last-child {
	border-bottom-left-radius: 5px;
	border-bottom: 2px solid #305BF2;
}

#invoice-overdue-modal .table tbody tr:last-child td{
	border-bottom-left-radius: 5px;
}
</style>
